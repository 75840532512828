var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.processing } },
        [
          _c(
            "a-row",
            { attrs: { type: "flex", justify: "center" } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _vm.dataSource.length
                    ? _c("a-table", {
                        attrs: {
                          bordered: "",
                          dataSource: _vm.dataSource,
                          columns: _vm.columns,
                          rowKey: function(record) {
                            return record.id
                          },
                          pagination: false
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "row_index",
                              fn: function(text, record, index) {
                                return [_c("p", [_vm._v(_vm._s(index + 1))])]
                              }
                            },
                            {
                              key: "user_name",
                              fn: function(text, record) {
                                return [
                                  _c("p", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.get(record, "user.nom_fr")) +
                                        "\n              " +
                                        _vm._s(
                                          _vm.get(record, "user.prenom_fr")
                                        ) +
                                        "\n            "
                                    )
                                  ])
                                ]
                              }
                            },
                            {
                              key: "reference",
                              fn: function(text, record) {
                                return [
                                  _c(
                                    "span",
                                    {
                                      class: {
                                        black: _vm.isNewPaiement(record)
                                      }
                                    },
                                    [
                                      _vm.isNewPaiement(record)
                                        ? _c("a-badge", {
                                            attrs: { status: "processing" }
                                          })
                                        : _vm._e(),
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(record.code) +
                                          "\n            "
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            },
                            {
                              key: "exercice_du",
                              fn: function(text, record) {
                                return [
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("year")(
                                          _vm.get(record, "exercice.annee")
                                        )
                                      )
                                    )
                                  ])
                                ]
                              }
                            },
                            {
                              key: "piece_jointe",
                              fn: function(text, record) {
                                return [
                                  _c(
                                    "a-tooltip",
                                    { attrs: { placement: "top" } },
                                    [
                                      _c("template", { slot: "title" }, [
                                        _c("span", [_vm._v("Visualiser")])
                                      ]),
                                      record.file_name
                                        ? _c("a-icon", {
                                            staticClass: "action_icons",
                                            attrs: { type: "search" },
                                            on: {
                                              click: function($event) {
                                                return _vm.previewDocument(
                                                  record.file_link
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                ]
                              }
                            },
                            {
                              key: "date_paiement",
                              fn: function(text, record) {
                                return [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("date")(record.dt_encaissement)
                                      )
                                    )
                                  ])
                                ]
                              }
                            },
                            {
                              key: "etat",
                              fn: function(text, record) {
                                return [
                                  _c("EtatDemandesOuverturesIndicator", {
                                    attrs: { etat: record.etat }
                                  })
                                ]
                              }
                            },
                            {
                              key: "user",
                              fn: function(text, record) {
                                return [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(record.nom_fr) +
                                      " " +
                                      _vm._s(record.prenom_fr) +
                                      "\n          "
                                  )
                                ]
                              }
                            },
                            {
                              key: "dt_demande",
                              fn: function(text, record) {
                                return [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.date(record.dt_demande)) +
                                      "\n          "
                                  )
                                ]
                              }
                            },
                            {
                              key: "admin_operation",
                              fn: function(text, record) {
                                return [
                                  _vm.isEtatDemandeOuvertureAccepter(
                                    record.etat
                                  )
                                    ? _c(
                                        "a-tooltip",
                                        { attrs: { placement: "top" } },
                                        [
                                          _c("template", { slot: "title" }, [
                                            _c("span", [_vm._v("Imprimer")])
                                          ]),
                                          _c("a-icon", {
                                            staticClass: "action_icons",
                                            attrs: {
                                              theme: "twoTone",
                                              type: "printer"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.printDocument(record)
                                              }
                                            }
                                          })
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                  _c(
                                    "a-tooltip",
                                    { attrs: { placement: "top" } },
                                    [
                                      _c("template", { slot: "title" }, [
                                        _c("span", [_vm._v("Edition")])
                                      ]),
                                      _c("a-icon", {
                                        staticClass:
                                          "margin_right action_icons",
                                        attrs: {
                                          type: "edit",
                                          theme: "twoTone",
                                          twoToneColor: "#52c41a"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.goToUserDemandes(
                                              record.user_id
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    2
                                  )
                                ]
                              }
                            },
                            {
                              key: "operation",
                              fn: function(text, record) {
                                return [
                                  _vm.canEdit(record)
                                    ? _c(
                                        "a-tooltip",
                                        { attrs: { placement: "top" } },
                                        [
                                          _c("template", { slot: "title" }, [
                                            _c("span", [_vm._v("Edition")])
                                          ]),
                                          _c("a-icon", {
                                            staticClass:
                                              "margin_right action_icons",
                                            attrs: {
                                              type: "edit",
                                              theme: "twoTone",
                                              twoToneColor: "#52c41a"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.onEdit(record.id)
                                              }
                                            }
                                          })
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                  _vm.isEtatDemandeOuvertureAccepter(
                                    record.etat
                                  )
                                    ? _c(
                                        "a-tooltip",
                                        { attrs: { placement: "top" } },
                                        [
                                          _c("template", { slot: "title" }, [
                                            _c("span", [_vm._v("Imprimer")])
                                          ]),
                                          _c("a-icon", {
                                            staticClass: "action_icons",
                                            attrs: {
                                              theme: "twoTone",
                                              type: "printer"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.printDocument(record)
                                              }
                                            }
                                          })
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                  _c(
                                    "a-tooltip",
                                    { attrs: { placement: "top" } },
                                    [
                                      _c("template", { slot: "title" }, [
                                        _c("span", [_vm._v("Les visites")])
                                      ]),
                                      _c("a-icon", {
                                        staticClass:
                                          "margin_right action_icons",
                                        attrs: {
                                          type: "eye",
                                          theme: "twoTone",
                                          twoToneColor: "#52c41a"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.goToVisite(record.id)
                                          }
                                        }
                                      })
                                    ],
                                    2
                                  ),
                                  _vm.canDelete(record)
                                    ? _c(
                                        "a-popconfirm",
                                        {
                                          attrs: {
                                            title:
                                              "Etes-vous sûr de vouloir supprimer cette ligne ?"
                                          },
                                          on: {
                                            confirm: function() {
                                              return _vm.onDelete(record.id)
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "a-tooltip",
                                            { attrs: { placement: "top" } },
                                            [
                                              _c(
                                                "template",
                                                { slot: "title" },
                                                [
                                                  _c("span", [
                                                    _vm._v("Suppression")
                                                  ])
                                                ]
                                              ),
                                              _c("a-icon", {
                                                staticClass: "action_icons",
                                                attrs: {
                                                  type: "delete",
                                                  theme: "twoTone",
                                                  twoToneColor: "#eb2f96"
                                                }
                                              })
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              }
                            },
                            {
                              key: "decision",
                              fn: function(text, record) {
                                return [
                                  record.visites.length
                                    ? _c(
                                        "a-tooltip",
                                        { attrs: { placement: "top" } },
                                        [
                                          _c("template", { slot: "title" }, [
                                            _c("span", [_vm._v("Décision")])
                                          ]),
                                          _c(
                                            "a-button",
                                            {
                                              attrs: { type: "link" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.onEditDeicion(
                                                    record.id
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                Décision\n              "
                                              )
                                            ]
                                          )
                                        ],
                                        2
                                      )
                                    : _vm._e()
                                ]
                              }
                            },
                            {
                              key: "expandedRowRender",
                              fn: function(record) {
                                return _c(
                                  "div",
                                  { staticStyle: { margin: "0" } },
                                  [
                                    _vm.isDemandeOuvertureTransfert(
                                      record.type.code
                                    )
                                      ? _c("div", [
                                          _c("p", [
                                            _c("b", [
                                              _vm._v("Wilaya précédent : ")
                                            ]),
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.get(
                                                    record,
                                                    "wilaya_precedent.nom_fr"
                                                  )
                                                ) +
                                                "\n              "
                                            )
                                          ]),
                                          _c("p", [
                                            _c("b", [
                                              _vm._v("Commune précédent : ")
                                            ]),
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.get(
                                                    record,
                                                    "commune_precedent.nom_fr"
                                                  )
                                                ) +
                                                "\n              "
                                            )
                                          ]),
                                          _c("p", [
                                            _c("b", [
                                              _vm._v("Addresse précédent : ")
                                            ]),
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.get(
                                                    record,
                                                    "adresse_precedent"
                                                  )
                                                ) +
                                                "\n              "
                                            )
                                          ]),
                                          _c("p", [
                                            _c("b", [
                                              _vm._v(
                                                "Addresse précédent (Ar): "
                                              )
                                            ]),
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.get(
                                                    record,
                                                    "adresse_precedent_ar"
                                                  )
                                                ) +
                                                "\n              "
                                            )
                                          ])
                                        ])
                                      : _vm._e(),
                                    _c("p", [
                                      _c("b", [_vm._v("Wilaya : ")]),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.get(record, "wilaya.nom_fr")
                                          )
                                      )
                                    ]),
                                    _c("p", [
                                      _c("b", [_vm._v("Commune : ")]),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.get(record, "commune.nom_fr")
                                          )
                                      )
                                    ]),
                                    _c("p", [
                                      _c("b", [_vm._v("Addresse")]),
                                      _vm._v(" : " + _vm._s(record.adresse))
                                    ]),
                                    _c("p", [
                                      _c("b", [_vm._v("Addresse (Ar)")]),
                                      _vm._v(" : " + _vm._s(record.adresse_ar))
                                    ]),
                                    _c("p", [
                                      _c("b", [_vm._v("Superfice")]),
                                      _vm._v(" : " + _vm._s(record.superficie))
                                    ]),
                                    _c("p", [
                                      _c("b", [_vm._v("Tél. :")]),
                                      _vm._v(" " + _vm._s(record.tel))
                                    ]),
                                    _c("p", [
                                      _c("b", [_vm._v("ANSEJ :")]),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            record.is_ansej ? "Oui" : "Non"
                                          )
                                      )
                                    ]),
                                    _vm.isDemandeOuvertureCabinetGroupe(
                                      record.type.code
                                    )
                                      ? _c("p", [
                                          _c("b", [_vm._v("Les membres :")]),
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(_vm.getMembers(record)) +
                                              "\n              "
                                          ),
                                          _c("br"),
                                          _c("br"),
                                          _c("b", [_vm._v("Les médecins :")]),
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(_vm.getMedecins(record)) +
                                              "\n            "
                                          )
                                        ])
                                      : _vm._e()
                                  ]
                                )
                              }
                            }
                          ],
                          null,
                          false,
                          3879499626
                        )
                      })
                    : _c("EmptyResult")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }